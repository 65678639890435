import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
    Modal, Box, TextField, Select, MenuItem, Button, IconButton,
    Typography, CircularProgress, FormControlLabel, Switch, FormControl
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useParams } from 'react-router-dom';
import { debounce } from 'lodash';
import SuccessScreen from '../SuccessScreen';
import ErrorManager from '../ErrorManager';
import { Autocomplete } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import '../modalStyle.css';
import { fetchBots } from '../../application/service/apiService';
import useFeatureFlag from '../../hooks/useFeatureFlag';

const WabaModal = ({ open, onClose, channelId, channelName }) => {
    const [templates, setTemplates] = useState([]);
    const [usuarios, setUsuarios] = useState([]);
    const [usuarioSelecionado, setUsuarioSelecionado] = useState('');
    const [templateIdSelecionado, setTemplateIdSelecionado] = useState('');
    const [schedule_datetime, setSchedule_datetime] = useState('');
    const [arquivoCsv, setArquivoCsv] = useState(null);
    const [arquivoCsvUrl, setArquivoCsvUrl] = useState('');
    const [entryInternationalPhone, setEntryInternationalPhone] = useState(false);
    const [adicionarEtiqueta, setAdicionarEtiqueta] = useState(true);
    const [contatosDuplicados, setContatosDuplicados] = useState(true);
    const [fecharChat, setFecharChat] = useState(true);
    const [email, setEmail] = useState('');
    const [showSuccessScreen, setShowSuccessScreen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isCsvUploaded, setIsCsvUploaded] = useState(false);
    const [isCsvDragActive, setIsCsvDragActive] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const [etiqueta, setEtiqueta] = useState('');
    const [bots, setBots] = useState([]);
    const [selectedBot, setSelectedBot] = useState('');

    const { acontID, token } = useParams();
    const { hasPermission: hasBotFeature, isReady: isBotFeatureReady } = useFeatureFlag(acontID);
    const errorManagerRef = useRef(null);
    const fileInputRef = useRef(null);
    const dataToken = JSON.parse(localStorage.getItem('dataToken'));
    const tokenCliente = dataToken ? dataToken.token : null;

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    useEffect(() => {
        const fetchTemplates = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL_POLI_QA}/api/v1/templates/${acontID}`, {
                    headers: { 'Authorization': `Bearer ${tokenCliente}` }
                });
                if (!response.ok) throw new Error('Erro ao buscar templates');
                const data = await response.json();
                setTemplates(data);
            } catch (error) {
                errorManagerRef.current?.showError('Erro ao buscar templates');
            }
        };

        const fetchUsuarios = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_URL_DISP}/get-users/channels/${channelId}?perPage=150`, {
                    headers: { 'Authorization': `Bearer ${token}` }
                });
                if (!response.ok) throw new Error('Erro ao buscar usuários');
                const data = await response.json();
                setUsuarios(data.data);
            } catch (error) {
                errorManagerRef.current?.showError('Erro ao buscar usuários');
            }
        };

        if (open) {
            fetchTemplates();
            fetchUsuarios();
            const now = new Date();
            setSchedule_datetime(formatDate(now));
        }
    }, [open, acontID, tokenCliente, channelId, token]);

    const debouncedHandleInputChange = useCallback(
        debounce(async (value) => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_URL_DISP}/get-users/channels/${channelId}?perPage=150&entry_user_name=${value}`,
                    { headers: { 'Authorization': `Bearer ${token}` } }
                );
                if (response.ok) {
                    const data = await response.json();
                    setUsuarios(data.data);
                }
            } catch (error) {
                errorManagerRef.current?.showError('Erro ao buscar usuários');
            }
        }, 300),
        [channelId, token]
    );

    const handleInputChange = (event, value) => {
        if (!value) {
            const fetchUsuarios = async () => {
                try {
                    const response = await fetch(`${process.env.REACT_APP_URL_DISP}/get-users/channels/${channelId}?perPage=150`, {
                        headers: { 'Authorization': `Bearer ${token}` }
                    });
                    if (!response.ok) throw new Error('Erro ao buscar usuários');
                    const data = await response.json();
                    setUsuarios(data.data);
                } catch (error) {
                    errorManagerRef.current?.showError('Erro ao buscar usuários');
                }
            };
            fetchUsuarios();
        } else {
            debouncedHandleInputChange(value);
        }
    };

    const handleFileChange = async (event) => {
        const file = event.target.files?.[0];
        if (file && file.type === 'text/csv') {
            setArquivoCsv(file);
            const url = await handleFileUpload(file);
            if (url) {
                setArquivoCsvUrl(url);
                setIsCsvUploaded(true);
                // Remove o erro relacionado ao CSV do estado formErrors
                setFormErrors((prev) => {
                    const { csv, ...rest } = prev;
                    return rest;
                });
            }
        } else {
            errorManagerRef.current?.showError('Por favor, selecione um arquivo .csv');
        }
    };

    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_URL_DISP}/upload/customer/${acontID}`, {
                method: 'POST',
                headers: { 'Authorization': `Bearer ${token}` },
                body: formData,
            });
            if (response.ok) {
                const data = await response.json();
                return data.fileUrl;
            }
            throw new Error('Erro no upload');
        } catch (error) {
            errorManagerRef.current?.showError('Erro ao fazer upload do arquivo');
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const submitTrigger = async (isScheduled) => {
        const formData = new FormData();
        formData.append('entry_tag', templateIdSelecionado);
        formData.append('entry_link_file', arquivoCsvUrl);
        formData.append('entry_chat_close', fecharChat ? 'true' : 'false');
        formData.append('entry_deduplicate_contact', contatosDuplicados ? 'true' : 'false');
        formData.append('entry_create_tag', adicionarEtiqueta ? 'true' : 'false');
        formData.append('entry_international_phone', entryInternationalPhone ? 'true' : 'false');
        formData.append('entry_email', email);
        if (etiqueta) formData.append('entry_custom_tag_name', etiqueta);
        if (fecharChat) formData.append('entry_bot_legacy_id', selectedBot || '');
        if (isScheduled) formData.append('schedule_datetime', formatDate(new Date(schedule_datetime)));

        const response = await fetch(
            `${process.env.REACT_APP_URL_DISP}/trigger/WABA/${acontID}/${channelId}/${usuarioSelecionado}`,
            {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'accept': 'application/json',
                    'Connection': 'keep-alive',
                },
                body: formData,
            }
        );

        if (response.ok) {
            setShowSuccessScreen(true);
        } else {
            const errorText = await response.text();
            throw new Error(`Erro na requisição: ${response.status} - ${errorText}`);
        }
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setIsSubmitting(true);

        const currentDate = new Date();
        const maxDate = new Date(currentDate);
        maxDate.setDate(maxDate.getDate() + 6);
        const selectedDate = new Date(schedule_datetime);

        const errors = {};

        if (!usuarioSelecionado) errors.usuario = 'Usuário é obrigatório';
        if (!templateIdSelecionado) errors.template = 'Template é obrigatório';
        if (!schedule_datetime) errors.date = 'Data é obrigatória';
        if (!arquivoCsvUrl) errors.csv = 'Arquivo CSV é obrigatório';
        if (!email) {
            errors.email = 'Email é obrigatório';
        } else if (!validateEmail(email)) {
            errors.email = 'Por favor, insira um email válido';
        }

        if (selectedDate > maxDate) errors.date = 'A data ultrapassa o período permitido';

        setFormErrors(errors);

        if (Object.keys(errors).length > 0) {
            setIsSubmitting(false);
            return;
        }

        try {
            await submitTrigger(selectedDate > currentDate);
        } catch (error) {
            errorManagerRef.current?.showError(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleRemoveCsv = () => {
        setArquivoCsv(null);
        setArquivoCsvUrl('');
        setIsCsvUploaded(false);
        if (fileInputRef.current) {
            fileInputRef.current.value = '';
        }
    };

    const handleCsvDragOver = (event) => {
        event.preventDefault();
        setIsCsvDragActive(true);
    };

    const handleCsvDragLeave = () => setIsCsvDragActive(false);

    const handleCsvDrop = async (event) => {
        event.preventDefault();
        setIsCsvDragActive(false);
        const file = event.dataTransfer.files[0];
        if (file && file.type === 'text/csv') {
            setArquivoCsv(file);
            const url = await handleFileUpload(file);
            if (url) {
                setArquivoCsvUrl(url);
                setIsCsvUploaded(true);
                // Remove o erro relacionado ao CSV do estado formErrors
                setFormErrors((prev) => {
                    const { csv, ...rest } = prev;
                    return rest;
                });
            }
        } else {
            errorManagerRef.current?.showError('Por favor, selecione um arquivo .csv');
        }
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const fetchBotsData = useCallback(async () => {
        try {
            const botsData = await fetchBots(acontID, channelId, token);
            setBots(botsData.data);
        } catch (error) {
            errorManagerRef.current?.showError('Erro ao buscar os bots');
        }
    }, [acontID, channelId, token]);

    useEffect(() => {
        if (open && isBotFeatureReady && hasBotFeature) {
            fetchBotsData();
        }
    }, [open, isBotFeatureReady, hasBotFeature, fetchBotsData]);

    return (
        <>
            <ErrorManager ref={errorManagerRef} />
            <Modal
                open={open}
                onClose={onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal">
                    <form className="modal-content" onSubmit={handleFormSubmit}>
                        <Box display="flex" alignItems="center" mb={2}>
                            <Box flexGrow={1} textAlign="center">
                                <Typography variant="h6" style={{ fontWeight: 'normal', margin: 0 }}>
                                    Criar campanha
                                </Typography>
                            </Box>
                            <IconButton onClick={onClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <div className="input-container">
                            <Typography variant="subtitle1" sx={{ my: 2 }}>
                                Canal selecionado: <strong>{channelName}</strong>
                            </Typography>
                        </div>
                        <div className="input-container">
                            <Autocomplete
                                fullWidth
                                options={usuarios}
                                getOptionLabel={(option) => option.name}
                                value={usuarios.find(usuario => usuario.id === usuarioSelecionado) || null}
                                onChange={(event, newValue) => {
                                    setUsuarioSelecionado(newValue ? newValue.id : '');
                                }}
                                onInputChange={handleInputChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Selecione o usuário"
                                        margin="normal"
                                        fullWidth
                                        required
                                        error={!!formErrors.usuario}
                                        helperText={formErrors.usuario}
                                    />
                                )}
                                noOptionsText="Digite para buscar"
                            />
                        </div>
                        <div className="input-container">
                            <TextField
                                fullWidth
                                label="Data e horário de envio"
                                margin="normal"
                                type="datetime-local"
                                value={schedule_datetime}
                                onChange={(event) => {
                                    const currentDate = new Date();
                                    const maxDate = new Date(currentDate);
                                    maxDate.setDate(maxDate.getDate() + 6);
                                    const selectedDate = new Date(event.target.value);
                                    if (selectedDate > maxDate) {
                                        setFormErrors({ date: 'A data ultrapassa o período permitido para agendamentos' });
                                    } else {
                                        setFormErrors(prev => ({ ...prev, date: '' }));
                                    }
                                    setSchedule_datetime(event.target.value);
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                required
                                error={!!formErrors.date}
                                helperText={formErrors.date}
                            />
                        </div>
                        <div className="input-container">
                            <Autocomplete
                                fullWidth
                                options={templates.filter(template => template.is_template === true && template.channel_id === channelId)}
                                getOptionLabel={(option) => option.tag}
                                value={templates.find(template => template.tag === templateIdSelecionado) || null}
                                onChange={(event, newValue) => {
                                    setTemplateIdSelecionado(newValue ? newValue.tag : '');
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Template da mensagem"
                                        margin="normal"
                                        fullWidth
                                        required
                                        error={!!formErrors.template}
                                        helperText={formErrors.template}
                                    />
                                )}
                                noOptionsText="Nenhum template encontrado"
                            />
                        </div>
                        <div
                            className={`upload-container ${isCsvDragActive ? 'drag-active' : ''}`}
                            onDragOver={handleCsvDragOver}
                            onDragLeave={handleCsvDragLeave}
                            onDrop={handleCsvDrop}
                            style={{
                                border: '2px dashed #ccc',
                                borderColor: formErrors.csv ? 'red' : '#ccc',
                                borderRadius: '8px',
                                padding: '16px',
                                textAlign: 'center',
                                position: 'relative',
                            }}
                        >
                            <Box display="flex" alignItems="center" justifyContent="center" mb={2}>
                                <CloudUploadIcon style={{ marginRight: '8px', color: '#5a5a5a' }} />
                                <Typography variant="body1" style={{ marginRight: '16px' }}>
                                    Envie sua planilha em .csv
                                </Typography>
                                <Button
                                    variant="contained"
                                    component="label"
                                    color="primary"
                                >
                                    {isLoading ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Escolher Arquivo'}
                                    <input
                                        type="file"
                                        name="csvFile"
                                        accept=".csv"
                                        hidden
                                        ref={fileInputRef}
                                        onChange={handleFileChange}
                                    />
                                </Button>
                            </Box>
                            {isCsvUploaded && (
                                <div className="file-preview" style={{ marginTop: '8px' }}>
                                    <a href={arquivoCsvUrl} download={arquivoCsv ? arquivoCsv.name : 'arquivo.csv'}>
                                        {arquivoCsv ? arquivoCsv.name : 'arquivo.csv'}
                                    </a>
                                    <div className="file-actions" style={{ display: 'inline-flex', alignItems: 'center', marginLeft: '8px' }}>
                                        <IconButton onClick={handleRemoveCsv} size="small" className="remove-button">
                                            <DeleteIcon />
                                        </IconButton>
                                        <CheckCircleIcon className="check-icon" color="success" />
                                    </div>
                                </div>
                            )}
                            {formErrors.csv && (
                                <Typography variant="caption" color="error" style={{ display: 'block', marginTop: '8px' }}>
                                    É necessário anexar um arquivo CSV
                                </Typography>
                            )}
                        </div>
                        <Box display="flex" justifyContent="flex-start" alignItems="center" mt={1}>
                            <Typography variant="caption" color="textSecondary" mr={2}>
                                Baixe o modelo no botão ao lado
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={() => window.open('https://docs.google.com/spreadsheets/d/1wrbJz7rIPBi26xssXseUD8UQT5esdmh6LN3fNMzyEyo/copy', '_blank')}
                            >
                                Planilha Modelo
                            </Button>
                        </Box>
                        <div className="input-container">
                            <TextField
                                fullWidth
                                label="Email"
                                margin="normal"
                                value={email}
                                onChange={(e) => {
                                    const newEmail = e.target.value;
                                    setEmail(newEmail);
                                    if (!newEmail) {
                                        setFormErrors((prev) => ({ ...prev, email: 'Email é obrigatório' }));
                                    } else if (!validateEmail(newEmail)) {
                                        setFormErrors((prev) => ({ ...prev, email: 'Por favor, insira um email válido' }));
                                    } else {
                                        setFormErrors((prev) => ({ ...prev, email: '' }));
                                    }
                                }}
                                required
                                error={!!formErrors.email}
                                helperText={formErrors.email}
                            />
                        </div>
                        <div className="input-container">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={adicionarEtiqueta}
                                        onChange={(e) => setAdicionarEtiqueta(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Adicionar etiqueta ao contato"
                            />
                        </div>
                        {adicionarEtiqueta && (
                            <div className="input-container">
                                <TextField
                                    fullWidth
                                    label="Nome da etiqueta (opcional)"
                                    margin="normal"
                                    value={etiqueta}
                                    onChange={(e) => setEtiqueta(e.target.value)}
                                />
                            </div>
                        )}
                        <div className="input-container">
                            <Typography variant="body2">Ao ativar, caso o cliente responda o bot será acionado e o contato poderá ser distribuído</Typography>
                            <Select
                                fullWidth
                                value={fecharChat}
                                onChange={(e) => setFecharChat(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        {fecharChat && hasBotFeature && (
                            <div className="input-container">
                                <FormControl fullWidth margin="normal">
                                    <Select
                                        labelId="select-bot-label"
                                        id="select-bot"
                                        fullWidth
                                        value={selectedBot || ""}
                                        onChange={(e) => setSelectedBot(e.target.value)}
                                        displayEmpty
                                    >
                                        <MenuItem value="" disabled>Selecione o Bot</MenuItem>
                                        {bots.map((bot) => (
                                            <MenuItem key={bot.legacy_flow_id} value={bot.legacy_flow_id}>
                                                {bot.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        )}
                        <div className="input-container">
                            <Typography variant="body2">Ao ativar, evitará que o contato receba duas mensagens</Typography>
                            <Select
                                fullWidth
                                value={contatosDuplicados}
                                onChange={(e) => setContatosDuplicados(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="input-container">
                            <Typography variant="body2">Caso verdadeiro não vai adicionar DDI 55 nos telefones que não tem</Typography>
                            <Select
                                fullWidth
                                value={entryInternationalPhone}
                                onChange={(e) => setEntryInternationalPhone(e.target.value)}
                                displayEmpty
                                margin="normal"
                                required
                            >
                                <MenuItem value={true}>Sim</MenuItem>
                                <MenuItem value={false}>Não</MenuItem>
                            </Select>
                        </div>
                        <div className="button-container">
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Enviar'}
                            </Button>
                        </div>
                    </form>
                    {showSuccessScreen && <SuccessScreen />}
                </div>
            </Modal>
        </>
    );
};

export default WabaModal;