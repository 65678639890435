import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';

const root = ReactDOM.createRoot(document.getElementById('root'));

const environmentID = process.env.REACT_APP_FLAGSMITH_ENV_KEY;

root.render(
  <React.StrictMode>
    <FlagsmithProvider
      flagsmith={flagsmith}
      options={{
        environmentID,
        cacheFlags: true,
        enableAnalytics: false,
      }}
    >
      <App />
    </FlagsmithProvider>
  </React.StrictMode>
);

reportWebVitals();
