import { useEffect, useState } from 'react';
import { useFlags, useFlagsmith, useFlagsmithLoading } from 'flagsmith/react';

const useFeatureFlag = (acontID) => {
  const flagName = process.env.REACT_APP_CHATBOT_ENABLE_CAMPAIGN;
  const flags = useFlags([flagName]);
  const flagsmith = useFlagsmith();
  const isLoading = useFlagsmithLoading();
  const [hasPermission, setHasPermission] = useState(false);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (!isLoading && acontID && flagsmith?.identify && flagsmith.environmentID) {
      flagsmith.identify(`customer_${acontID}`);
    }
  }, [acontID, flagsmith, isLoading]);

  useEffect(() => {
    const value = flags[flagName]?.value;
    const enabled = flags[flagName]?.enabled;

    if (enabled === false) {
      setHasPermission(true);
      setIsReady(true);
      return;
    }

    if (value != null && acontID != null) {
      let allowedIds = [];

      if (typeof value === 'string' && value.startsWith('[') && value.endsWith(']')) {
        try {
          allowedIds = JSON.parse(value).map(v => v.toString());
        } catch (e) {
          console.error('Erro ao analisar feature_state_value:', e);
        }
      } else if (typeof value === 'number') {
        allowedIds = [value.toString()];
      } else if (typeof value === 'string') {
        allowedIds = value.includes(',')
          ? value.split(',').map(v => v.trim())
          : [value.trim()];
      }

      const stringID = acontID.toString();
      const hasAccess = allowedIds.includes(stringID);

      setHasPermission(hasAccess);
      setIsReady(true);
    }
  }, [flags[flagName], acontID]);

  return { hasPermission, isReady };
};

export default useFeatureFlag;