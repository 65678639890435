import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import Lottie from 'react-lottie';
import { motion } from 'framer-motion';
import successAnimation from '../assets/animations/success-check.json';
import './successScreen.css';

const SuccessScreen = () => {
    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.reload();
        }, 7000);

        return () => clearTimeout(timer);
    }, []);

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: successAnimation,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    };

    const containerVariants = {
        hidden: { opacity: 0, scale: 0.8 },
        visible: {
            opacity: 1,
            scale: 1,
            transition: {
                duration: 0.5,
                ease: 'easeOut',
            },
        },
    };

    const textVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: {
            opacity: 1,
            y: 0,
            transition: {
                duration: 0.5,
                delay: 0.3,
                ease: 'easeOut',
            },
        },
    };

    const iconVariants = {
        hidden: { scale: 0 },
        visible: {
            scale: 1,
            transition: {
                duration: 0.5,
                ease: 'easeOut',
            },
        },
        pulse: {
            scale: [1, 1.1, 1],
            transition: {
                duration: 1,
                ease: 'easeInOut',
                repeat: Infinity,
                repeatDelay: 1,
            },
        },
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100vh"
            width="100vw"
            bgcolor="rgba(0, 0, 0, 0.5)"
            position="fixed"
            top={0}
            left={0}
            right={0}
            bottom={0}
            zIndex={1300}
        >
            <motion.div
                variants={containerVariants}
                initial="hidden"
                animate="visible"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <motion.div
                    variants={iconVariants}
                    initial="hidden"
                    animate={['visible', 'pulse']}
                >
                    <Lottie
                        options={defaultOptions}
                        height={300}
                        width={300}
                    />
                </motion.div>
                <motion.div
                    variants={textVariants}
                    initial="hidden"
                    animate="visible"
                    style={{
                        textAlign: 'center',
                    }}
                >
                    <Typography
                        variant="h3"
                        style={{
                            color: 'white',
                            marginTop: 10,
                            textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                            fontWeight: 'bold',
                        }}
                    >
                        Sucesso!
                    </Typography>
                    <Typography
                        variant="h6"
                        style={{
                            color: 'white',
                            marginTop: 5,
                            textShadow: '0 1px 2px rgba(0, 0, 0, 0.3)',
                        }}
                    >
                        Sua campanha foi criada com êxito.
                    </Typography>
                </motion.div>
            </motion.div>
        </Box>
    );
};

export default SuccessScreen;