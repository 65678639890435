import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  Box, Table, TableBody, Menu, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Modal, Select, FormControl, MenuItem, Pagination, IconButton, CircularProgress, Alert, TextField
} from '@mui/material';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useParams } from 'react-router-dom';
import myImage from '../../assets/images/img.png';
import BookIcon from '@mui/icons-material/Book';
import BrokerModal from '../modal_broker/BrokerModal';
import WabaModal from '../modal_waba/WabaModal';
import ErrorManager from '../ErrorManager';
import InstaModal from '../instagram/instaModal';
import TriggerDetailsModal from '../TriggerDetailsModal';
import { fetchChannels, cancelTrigger, fetchTriggersByStatus, fetchTriggersByDate, fetchTriggersByStatusAndDate, interruptTrigger } from '../../../src/application/service/apiService';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import brokerIcon from '../../assets/images/brokerIcon.svg';
import instaIcon from '../../assets/images/insta.svg';
import './Disparador.css';

const DisparadorContainer = styled(Box)({
  flexGrow: 1,
  padding: '20px',
});

const StatusButton = styled(Button)(({ status }) => ({
  backgroundColor: status === 'Concluído'
    ? '#28a745'
    : status === 'Falha'
      ? '#dc3545'
      : status === 'Em execução'
        ? '#ffc107'
        : status === 'Pendente'
          ? '#17a2b8'
          : status === 'Agendado'
            ? '#007bff'
            : status === 'Cancelado'
              ? '#6c757d'
              : status === 'Interrompido'
                ? '#ff5733'
                : '#dc3545',
  color: '#fff',
  borderRadius: '12px',
  padding: '5px 10px',
  textTransform: 'none',
  minWidth: '100px',
  maxWidth: '150px',
  height: '35px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '14px',
  '&:hover': {
    backgroundColor: status === 'Concluído'
      ? '#218838'
      : status === 'Falha'
        ? '#c82333'
        : status === 'Em execução'
          ? '#e0a800'
          : status === 'Pendente'
            ? '#138496'
            : status === 'Agendado'
              ? '#0056b3'
              : status === 'Cancelado'
                ? '#5a6268'
                : status === 'Interrompido'
                  ? '#e04e1f'
                  : '#c82333',
  },
  '@media (max-width: 600px)': {
    minWidth: '80px',
    maxWidth: '120px',
    fontSize: '12px',
    padding: '4px 8px',
  },
}));

const Disparador = () => {
  const { acontID, token } = useParams();
  const [rows, setRows] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState(null);
  const [secondaryModalOpen, setSecondaryModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [selectedTriggerDetails, setSelectedTriggerDetails] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isEmpty, setIsEmpty] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [channels, setChannels] = useState([]);
  const errorManagerRef = useRef(null);
  const [statusId, setStatusId] = useState('');
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [selectedTriggerId, setSelectedTriggerId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openMenuId, setOpenMenuId] = useState(null);
  const [interruptModalOpen, setInterruptModalOpen] = useState(false);
  const [isInterrupting, setIsInterrupting] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [channelPage, setChannelPage] = useState(1);


  const [channelPagination, setChannelPagination] = useState({
    lastPage: 1,
    total: 0,
    perPage: 10,
  });

  const today = new Date().toISOString().split('T')[0];

  const handleStartDateChange = (e) => {
    const selectedDate = e.target.value;

    if (selectedDate > today) {
      setError('A data inicial não pode ser maior que a data atual.');
    } else {
      setError('');
      setStartDate(selectedDate);

      if (endDate && selectedDate > endDate) {
        setEndDate('');
      }
    }
  };

  const handleMenuClick = (event, row) => {
    setOpenMenuId(row.id);
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setOpenMenuId(null);
    setAnchorEl(null);
    setSelectedRow(null);
  };

  useEffect(() => {
    if (statusId) {
      setCurrentPage(1);
      fetchData(1, false, true);
    }
  }, [statusId]);

  const handleEndDateChange = (e) => {
    const selectedDate = e.target.value;

    if (selectedDate < startDate) {
      setError('A data final não pode ser menor que a data inicial.');
    } else {
      setError('');
      setEndDate(selectedDate);
    }
  };

  const handleStatusChange = (e) => {
    setStatusId(e.target.value);
  };

  const getStatus = (status_id) => {
    if (status_id === 1) return 'Concluído';
    if (status_id === 2) return 'Falha';
    if (status_id === 3) return 'Em execução';
    if (status_id === 0) return 'Pendente';
    if (status_id === 4) return 'Agendado';
    if (status_id === 5) return 'Cancelado';
    if (status_id === 6) return 'Interrompido';
    return 'Erro';
  };

  const fetchData = async (page, resetDates = false, resetPagination = false) => {
    setIsLoading(true);
    try {
      let data;
      if (statusId && startDate && endDate) {
        data = await fetchTriggersByStatusAndDate(acontID, token, statusId, startDate, endDate, page);
      } else if (statusId) {
        data = await fetchTriggersByStatus(acontID, token, statusId, page);
      } else if (startDate && endDate) {
        data = await fetchTriggersByDate(acontID, token, startDate, endDate, page);
      } else {
        data = await fetchTriggersByStatusAndDate(acontID, token, '', '', '', page);
      }

      setRows(data.data || []);
      setTotalPages(data.last_page);
      setIsEmpty(data.data.length === 0);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsEmpty(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!token) {
      console.error('Token is missing');
      return;
    }

    fetchData(currentPage);
  }, [acontID, token, currentPage, startDate, endDate, statusId]);

  useEffect(() => {
    const loadChannels = async () => {
      try {
        
        setIsLoading(true);
        const { channels } = await fetchChannels(acontID, token);
        
        setChannels(channels);
      } catch (error) {
        console.error('Erro ao buscar os canais:', error);
        errorManagerRef.current.showError('Erro ao buscar os canais.');
      } finally {
        setIsLoading(false);
      }
    };

    loadChannels();
  }, [acontID, token]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleCreateClick = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedChannel(null);
  };

  const handleChannelSelect = (event) => {
    const channel = channels.find(ch => ch.id === event.target.value);
    setSelectedChannel(channel);
    setSecondaryModalOpen(true);
  };

  const closeSecondaryModal = () => {
    setSecondaryModalOpen(false);
    setSelectedChannel(null);
  };

  const handleBookIconClick = (details) => {
    setSelectedTriggerDetails(details);
    setDetailsModalOpen(true);
  };

  const closeDetailsModal = () => {
    setDetailsModalOpen(false);
    setSelectedTriggerDetails(null);
  };

  const handleCancelClick = (triggerId) => {
    setSelectedTriggerId(triggerId);
    setCancelModalOpen(true);
  };

  const handleCancelModalClose = () => {
    setCancelModalOpen(false);
    setSelectedTriggerId(null);
  };

  const handleConfirmCancel = async () => {
    setIsCanceling(true);
    try {
      await cancelTrigger(selectedTriggerId, acontID, token);
      setCancelModalOpen(false);
      setSelectedTriggerId(null);
      fetchData(currentPage);
    } catch (error) {
      console.error('Erro ao cancelar o trigger:', error);
    } finally {
      setIsCanceling(false);
    }
  };

  const handleInterruptClick = (triggerId) => {
    setSelectedTriggerId(triggerId);
    setInterruptModalOpen(true);
  };

  const handleInterruptModalClose = () => {
    setInterruptModalOpen(false);
    setSelectedTriggerId(null);
  };

  const handleConfirmInterrupt = async () => {
    setIsInterrupting(true);
    try {
      await interruptTrigger(acontID, token);
      await new Promise(resolve => setTimeout(resolve, 15000));
      setInterruptModalOpen(false);
      setSelectedTriggerId(null);
      setAnchorEl(null);
      setSelectedRow(null);
      fetchData(currentPage);
    } catch (error) {
      errorManagerRef.current.showError('Erro ao interromper o disparo. Por favor, tente novamente.');
    } finally {
      setIsInterrupting(false);
    }
  };

  return (
    <>
      <ErrorManager ref={errorManagerRef} />
      <DisparadorContainer>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
          <Box display="flex" flexDirection="column" gap={1}>
            <Typography variant="subtitle1" color="textSecondary">
              <strong>Filtro por data</strong>
            </Typography>
            <Box display="flex" gap={2}>
              <TextField
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                max={today}
                variant="outlined"
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#1976d2', // Borda azulada
                    },
                    '&:hover fieldset': {
                      borderColor: '#1976d2', // Mantém a borda azulada ao passar o mouse
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2', // Mantém a borda azulada ao focar
                    },
                  },
                }}
              />
              <TextField
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                min={startDate}
                variant="outlined"
                size="small"
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#1976d2', // Borda azulada
                    },
                    '&:hover fieldset': {
                      borderColor: '#1976d2', // Mantém a borda azulada ao passar o mouse
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#1976d2', // Mantém a borda azulada ao focar
                    },
                  },
                }}
              />
              <FormControl variant="outlined" size="small">
                <Select
                  value={statusId}
                  onChange={handleStatusChange}
                  displayEmpty
                  sx={{
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#1976d2',
                    },
                    '&:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#1976d2',
                    },
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#1976d2',
                    },
                  }}
                >
                  <MenuItem value="">Todos os Status</MenuItem>
                  <MenuItem value="0">Pendente</MenuItem>
                  <MenuItem value="1">Concluído</MenuItem>
                  <MenuItem value="2">Falha</MenuItem>
                  <MenuItem value="3">Em execução</MenuItem>
                  <MenuItem value="4">Agendado</MenuItem>
                  <MenuItem value="5">Cancelado</MenuItem>
                </Select>
              </FormControl>
            </Box>
            {error && <Alert severity="error">{error}</Alert>}
          </Box>
          <Button variant="contained" color="primary" onClick={handleCreateClick}>
            Criar nova campanha
          </Button>
        </Box>

        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="70vh">
            <CircularProgress style={{ color: '#1976d2' }} />
          </Box>
        ) : (
          <>
            {isEmpty ? (
              <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="70vh">
                <img src={myImage} alt="No Data" style={{ width: '500px', height: 'auto' }} />
                <Typography variant="h6" color="textSecondary" mt={2}>
                  Não foram encontrados dados. Tente criar uma nova campanha.
                </Typography>
              </Box>
            ) : (
              <>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Tag</strong></TableCell>
                        <TableCell><strong>Resultado</strong></TableCell>
                        <TableCell><strong>Canal</strong></TableCell>
                        <TableCell><strong>Data de envio</strong></TableCell>
                        <TableCell><strong>Responsável</strong></TableCell>
                        <TableCell><strong>Status</strong></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.id} hover>
                          <TableCell>{row.trigger_identify}</TableCell>
                          <TableCell className="IconContainer">
                            <IconButton onClick={() => handleBookIconClick(row)}>
                              <BookIcon className="BookIcon" />
                            </IconButton>
                          </TableCell>
                          <TableCell>
                            <Box display="flex" alignItems="center">
                              {(row.trigger_type === "BROKER" || row.trigger_type === "WABA") && <img src={brokerIcon} alt="Broker Icon" style={{ marginRight: '8px' }} />}
                              {row.trigger_type === "INSTAGRAM" && <img src={instaIcon} alt="Instagram Icon" style={{ marginRight: '8px' }} />}
                              <Typography>{row.channel_name || 'N/A'}</Typography>
                            </Box>
                          </TableCell>
                          <TableCell>{new Date(row.schedule_at || row.started_at || 'N/A').toLocaleString()}</TableCell>
                          <TableCell>{row.user_name || 'N/A'}</TableCell>
                          <TableCell>
                            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                              <StatusButton status={getStatus(row.status_id)} className={`StatusButton ${getStatus(row.status_id).toUpperCase()}`}>
                                {getStatus(row.status_id)}
                              </StatusButton>
                              {row.status_id === 4 && (
                                <>
                                  <IconButton onClick={(event) => handleMenuClick(event, row)}>
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl) && openMenuId === row.id}
                                    onClose={handleMenuClose}
                                  >
                                    <MenuItem onClick={() => handleCancelClick(selectedRow.id)}>Cancelar</MenuItem>
                                  </Menu>
                                </>
                              )}
                              {row.status_id === 3 && (
                                <>
                                  <IconButton onClick={(event) => handleMenuClick(event, row)}>
                                    <MoreVertIcon />
                                  </IconButton>
                                  <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl) && openMenuId === row.id}
                                    onClose={handleMenuClose}
                                  >
                                    <MenuItem onClick={() => handleInterruptClick(selectedRow.id)}>Interromper</MenuItem>
                                  </Menu>
                                </>
                              )}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box display="flex" justifyContent="center" mt={2}>
                  <Pagination
                    count={totalPages}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                  />
                </Box>
              </>
            )}
          </>
        )}

        <Modal
          open={interruptModalOpen}
          onClose={handleInterruptModalClose}
          aria-labelledby="modal-interrupt-confirmation"
          aria-describedby="modal-interrupt-confirmation-description"
        >
          <div className="modal">
            <div
              className="modal-content"
              style={{
                width: '600px',
                height: '187px',
                top: '418px',
                left: '420px',
                borderRadius: '10px',
                backgroundColor: 'white',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                padding: '20px',
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography id="modal-interrupt-confirmation" variant="h6" component="h2">
                  Cancelar disparo
                </Typography>
                <IconButton onClick={handleInterruptModalClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography id="modal-interrupt-confirmation-description" variant="body1" mb={2}>
                Tem certeza que deseja finalizar todos os disparos em execução?
              </Typography>
              <Box display="flex" justifyContent="flex-end" gap="24px">
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleInterruptModalClose();
                    handleMenuClose();
                  }}
                  sx={{
                    width: '267px',
                    height: '32px',
                    minWidth: '64px',
                    borderRadius: '4px',
                    borderWidth: '1px',
                    padding: '6px 16px',
                    borderColor: '#0000C8',
                    color: '#0000C8',
                    backgroundColor: 'white',
                    '&:hover': {
                      borderColor: '#0000A0',
                      backgroundColor: 'rgba(0, 0, 200, 0.04)',
                    },
                  }}
                >
                  Manter disparos
                </Button>
                <Button
                  variant="contained"
                  onClick={handleConfirmInterrupt}
                  disabled={isInterrupting}
                  sx={{
                    width: '267px',
                    height: '32px',
                    minWidth: '64px',
                    borderRadius: '4px',
                    borderWidth: '1px',
                    padding: '6px 16px',
                    backgroundColor: '#0000C8',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#0000A0',
                    },
                  }}
                >
                  {isInterrupting ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Confirmar cancelamento'}
                </Button>
              </Box>
            </div>
          </div>
        </Modal>

        <Modal
          open={modalOpen}
          onClose={handleModalClose}
          aria-labelledby="modal-type-selection"
          aria-describedby="modal-type-selection-description"
        >
          <div className="modal">
            <div
              className="modal-content"
              style={{
                width: '500px',
                margin: '0 auto',
                padding: '20px',
                borderRadius: '8px',
                backgroundColor: 'white',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography id="modal-type-selection" variant="h6" component="h2">
                  Criar campanha
                </Typography>
                <IconButton onClick={handleModalClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <FormControl fullWidth margin="normal">
                <Select
                  fullWidth
                  value={selectedChannel?.id || ''}
                  onChange={handleChannelSelect}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200,
                        overflowY: 'auto',
                      },
                    },
                  }}
                >
                  <MenuItem value="" disabled>Selecione um canal</MenuItem>
                  {channels.map(channel => (
                    <MenuItem key={channel.id} value={channel.id}>
                      {channel.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
        </Modal>

        {selectedChannel && selectedChannel.channel_id === 6 && (
          <WabaModal open={secondaryModalOpen} onClose={closeSecondaryModal} channelId={selectedChannel.id} channelName={selectedChannel.name} />
        )}

        {selectedChannel && selectedChannel.channel_id === 1 && (
          <BrokerModal open={secondaryModalOpen} onClose={closeSecondaryModal} channelId={selectedChannel.id} channelName={selectedChannel.name} />
        )}

        {selectedChannel && selectedChannel.channel_id === 5 && (
          <InstaModal open={secondaryModalOpen} onClose={closeSecondaryModal} channelId={selectedChannel.id} channelName={selectedChannel.name} />
        )}

        <TriggerDetailsModal
          open={detailsModalOpen}
          onClose={closeDetailsModal}
          details={selectedTriggerDetails}
        />

        <Modal
          open={cancelModalOpen}
          onClose={handleCancelModalClose}
          aria-labelledby="modal-cancel-confirmation"
          aria-describedby="modal-cancel-confirmation-description"
        >
          <div className="modal">
            <div
              className="modal-content"
              style={{
                width: '600px',
                height: '187px',
                top: '418px',
                left: '420px',
                borderRadius: '10px',
                backgroundColor: 'white',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                padding: '20px',
              }}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography id="modal-cancel-confirmation" variant="h6" component="h2">
                  Cancelar agendamento
                </Typography>
                <IconButton onClick={handleCancelModalClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Typography id="modal-cancel-confirmation-description" variant="body1" mb={2}>
                Tem certeza que deseja cancelar o agendamento?
              </Typography>
              <Box display="flex" justifyContent="flex-end" gap="24px">
                <Button
                  variant="outlined"
                  onClick={() => {
                    handleCancelModalClose();
                    handleMenuClose();
                  }}
                  sx={{
                    width: '267px',
                    height: '32px',
                    minWidth: '64px',
                    borderRadius: '4px',
                    borderWidth: '1px',
                    padding: '6px 16px',
                    borderColor: '#0000C8',
                    color: '#0000C8',
                    backgroundColor: 'white',
                    '&:hover': {
                      borderColor: '#0000A0',
                      backgroundColor: 'rgba(0, 0, 200, 0.04)',
                    },
                  }}
                >
                  Manter agendamento
                </Button>
                <Button
                  variant="contained"
                  onClick={handleConfirmCancel}
                  disabled={isCanceling}
                  sx={{
                    width: '267px',
                    height: '32px',
                    minWidth: '64px',
                    borderRadius: '4px',
                    borderWidth: '1px',
                    padding: '6px 16px',
                    backgroundColor: '#0000C8',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: '#0000A0',
                    },
                  }}
                >
                  {isCanceling ? <CircularProgress size={24} style={{ color: 'white' }} /> : 'Confirmar cancelamento'}
                </Button>
              </Box>
            </div>
          </div>
        </Modal>
      </DisparadorContainer>
    </>
  );
};

export default Disparador;