import React from 'react';
import { Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './home/Disparador';

const TriggerDetailsModal = ({ open, onClose, details }) => {
    const { failure_count, success_count, resulted, email } = details || {};

    return (
        <Modal open={open} onClose={onClose} aria-labelledby="trigger-details-modal">
            <Box className="CustomModal">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">Detalhes do Disparo</Typography>
                    <IconButton onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <Box mt={2}>
                    <Typography variant="body1"><strong>Sucesso:</strong> {success_count || 0}</Typography>
                    <Typography variant="body1"><strong>Falha:</strong> {failure_count || 0}</Typography>
                    <Typography variant="body1">
                        <strong>Resultado:</strong> {resulted !== null && resulted !== undefined ? resulted : 'Aguardando resultados'}
                    </Typography>
                    <Typography variant="body1"><strong>E-mail:</strong> {email || undefined}</Typography>
                </Box>
            </Box>
        </Modal>
    );
};

export default TriggerDetailsModal;
