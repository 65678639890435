import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_URL_DISP;

export const getTokenCliente = async (acontID, token) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get-token-internal?entry_customer_id=${acontID}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao buscar o token do cliente');
    }
  } catch (error) {
    console.error('Erro na API ao buscar o token do cliente:', error);
    throw error;
  }
};

export const cancelTrigger = async (triggerId, userId, token) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/trigger/cancel/${triggerId}/${userId}`, {
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao cancelar o trigger');
    }
  } catch (error) {
    console.error('Erro na API ao cancelar o trigger:', error);
    throw error;
  }
};

export const fetchTriggersByStatus = async (acontID, token, statusId, page) => {
  const statusParam = statusId ? `status_id=${statusId}&` : '';
  const pageParam = `page=${page}&`;

  try {
    const response = await axios.get(`${API_BASE_URL}/get-triggers/customer/${acontID}?${statusParam}${pageParam}perPage=20`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao buscar triggers por status');
    }
  } catch (error) {
    console.error('Erro na API ao buscar triggers por status:', error);
    throw error;
  }
};

export const fetchTriggersByDate = async (acontID, token, startDate, endDate, page) => {
  const start = startDate ? `${startDate} 00:00:01` : '';
  const end = endDate ? `${endDate} 23:59:00` : '';
  const pageParam = `page=${page}&`;

  try {
    const response = await axios.get(`${API_BASE_URL}/get-triggers/customer/${acontID}?${pageParam}perPage=20&start_date=${start}&end_date=${end}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao buscar triggers por data');
    }
  } catch (error) {
    console.error('Erro na API ao buscar triggers por data:', error);
    throw error;
  }
};

export const fetchTriggersByStatusAndDate = async (channelID, token, statusId, startDate, endDate, page) => {
  const statusParam = statusId ? `status_id=${statusId}&` : '';
  const start = startDate ? `${startDate} 00:00:01` : '';
  const end = endDate ? `${endDate} 23:59:00` : '';
  const pageParam = `page=${page}&`;

  try {
    const response = await axios.get(`${API_BASE_URL}/get-triggers/customer/${channelID}?${statusParam}${pageParam}perPage=20&start_date=${start}&end_date=${end}`, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao buscar triggers por status e data');
    }
  } catch (error) {
    console.error('Erro na API ao buscar triggers por status e data:', error);
    throw error;
  }
};

export const fetchBots = async (acontID, phoneNumber, token, page = 1, perPage = 20) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/get-bots/customer/${acontID}/${phoneNumber}?perPage=${perPage}&page=${page}`, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao buscar bots');
    }
  } catch (error) {
    console.error('Erro na API ao buscar bots:', error);
    throw error;
  }
};

export const interruptTrigger = async (acontID, token) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/trigger/interrupt/${acontID}?status_id=6`, {
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error('Erro ao interromper o trigger');
    }
  } catch (error) {
    console.error('Erro na API ao interromper o trigger:', error);
    throw error;
  }
};

export const fetchChannels = async (customerId, token) => {
  try {
    const dataToken = await getTokenCliente(customerId, token);
    if (!dataToken?.token) {
      throw new Error('Token inválido ou não encontrado');
    }

    localStorage.setItem('dataToken', JSON.stringify(dataToken));

    let allChannels = [];
    let currentPage = 1;
    let lastPage = 1;

    console.log(`Starting to fetch channels for customer ${customerId}`);

    while (currentPage <= lastPage) {
      console.log(`Fetching page ${currentPage} of ${lastPage}...`);

      const response = await axios.get(
        `${process.env.REACT_APP_URL_POLI_QA}/api/v1/customers/${customerId}/channels`,
        {
          headers: {
            Authorization: `Bearer ${dataToken.token}`,
            'Content-Type': 'application/json',
          },
          params: {
            page: currentPage,
          },
        }
      );

      if (response.status === 200) {
        // Filter channels for the current page (1: WhatsApp, 5: Instagram, 6: WABA)
        const filteredChannels = response.data.data.filter(
          (channel) =>
            channel.channel_id === 1 ||
            channel.channel_id === 6 ||
            channel.channel_id === 5
        );

        console.log(`Page ${currentPage}: Found ${filteredChannels.length} filtered channels`);


        allChannels = [...allChannels, ...filteredChannels];

        lastPage = response.data.last_page || 1;
        console.log(`Updated lastPage to ${lastPage}`);

        currentPage += 1;
      } else {
        throw new Error(`Erro ao buscar os canais na página ${currentPage}`);
      }
    }

    console.log(`Total channels fetched: ${allChannels.length}`);

    return {
      channels: allChannels,
    };
  } catch (error) {
    console.error('Erro na API ao buscar os canais:', error.message);
    throw new Error('Erro ao buscar os canais. Tente novamente mais tarde.');
  }
};