import React from 'react';
import { AppBar, Toolbar, Typography } from '@mui/material';
import './headerStyle.css';

const Header = () => {
  return (
    <AppBar position="static" className="custom-appbar"></AppBar>
  );
};

export default Header;
